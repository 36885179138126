<template>
    <div id="app">
        <van-nav-bar :title="informationDetails.title" :fixed="true"/>
        <div class="container">
            <p class="time">发布时间:{{formatDate(informationDetails.createtime)}}</p>
            <div class="content" v-html="escape2Html(informationDetails.content)"></div>
        </div>
    </div>
</template>

<script>
    import {getInformationDetails} from "../request/api";
    import {NavBar, Notify, Toast} from 'vant';
    import formatDate from '@/utils/time'

    export default {
        name: "Information",
        components: {
            [NavBar.name]: NavBar,
            [Notify.name]: Notify,
            [Toast.name]: Toast,
        },
        data(){
            return {
                informationDetails: {}
            }
        },
        mounted() {
            let information_id = this.$route.params.information_id
            getInformationDetails({'information_id':information_id}).then(response=>{
                if(response.code===1){
                    this.informationDetails = response.data
                }else{
                    Notify({type: 'danger', message: response.data.msg});
                }
            })
        },
        methods: {
            escape2Html: function (str = '') {
                var arrEntities = {'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"'};
                return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
                    return arrEntities[t];
                });
            },
            formatDate(time) {
                return formatDate(time)
            },
        },
    }
</script>

<style scoped>
    #app{
        width: 750px;
    }


    .time {
        font-size: 26px;
        color: #C0C0C0;
    }
    .content >>> img{
        width:100%;
        height:auto;
    }
    .container {
        padding: 0 30px;
        color: #545454;
        font-size: 30px;
        line-height: 50px;
        margin: 120px auto 0 auto;

    }

</style>