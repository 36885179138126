import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// 初始化时用sessionStore.getItem('token'),这样子刷新页面就无需重新登录
const state = {
    loginSuccess: window.sessionStorage.getItem('user'),
    token: window.sessionStorage.getItem('token'),
    btns:window.sessionStorage.getItem('btns'),
    identity: window.sessionStorage.getItem('identity')
};
const mutations = {
    //将token保存到sessionStorage里，token表示登陆状态
    SET_TOKEN: (state, data) => {
        state.token = data;
        window.sessionStorage.setItem('token', data);
    },
    //获取用户名
    GET_USER: (state, data) => {
        // 把用户名存起来
        state.user = data;
        window.sessionStorage.setItem('user', data);
    },
    BTNS: (state, data) => {
        state.btns = data;
        window.sessionStorage.setItem('btns', data);
    },
    //登出
    LOGOUT: state => {
        // 登出的时候要清除token
        state.token = null;
        state.user = null;
        window.sessionStorage.removeItem('token');
        window.sessionStorage.removeItem('user');
    },
    //获取用户名
    IDEN: (state, data) => {
        // 把用户名存起来
        state.identity = data;
        window.sessionStorage.setItem('identity', data);
    },
};

const actions = {};
export default new Vuex.Store({
    state,
    mutations,
    actions,
});
