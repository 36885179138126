import Vue from 'vue';
import Router from 'vue-router'
import Information from "../view/Information";

Vue.use(Router)
export default new Router({
    mode: 'history',
    routes: [
        {
            path: "/information_details/:information_id",
            name: 'information',
            component: Information
        }
    ]
})